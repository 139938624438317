<template>
  <div v-click-away="close" class="flex">
    <div class="relative flex">
      <div
        class="
          relative
          z-10
          flex
          items-center
          transition-colors
          ease-out
          duration-200
          text-sm
          font-medium
          py-2
          cursor-pointer
          select-none
        "
        :class="
          isHidden
            ? 'text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-100'
            : 'text-brand-500'
        "
        aria-expanded="false"
        @click="toggle()"
      >
        <span>{{ props.menu?.name }}</span>
        <i-heroicons-solid:chevron-down v-show="isHidden" class="ml-1" />
        <i-heroicons-solid:chevron-up v-show="!isHidden" class="ml-1" />
      </div>
    </div>
    <transition
      enter-from-class="opacity-0 -translate-y-1"
      enter-to-class="opacity-100 translate-y-0"
      leave-from-class="opacity-100 translate-y-0"
      leave-to-class="opacity-0 -translate-y-1"
      class="transition ease-out duration-150"
    >
      <div
        v-if="!isHidden"
        class="absolute top-full inset-x-0 bg-gray-800 text-white"
      >
        <cgn-lazy-image class="absolute inset-0 object-cover h-full w-full opacity-40 saturate-50" force-size :image="menu?.image" />
        <div class="max-w-7xl flex gap-8 p-8 mx-auto max-h-96 relative">
          <div v-if="column1?.name">
            <div class="text-lg">
              {{ column1.name }}
            </div>
            <div v-for="link in column1.children" :key="link.name" class="text-gray-200 hover:text-gray-100 py-1">
              <router-link :to="link.link">
                {{ link.name }}
              </router-link>
            </div>
          </div>
          <div v-if="column2?.name">
            <div class="text-lg">
              {{ column2.name }}
            </div>
            <div v-for="link in column2.children" :key="link.name" class="text-gray-200 hover:text-gray-100 py-1">
              <router-link :to="link.link">
                {{ link.name }}
              </router-link>
            </div>
          </div>
          <div>
            <div v-for="link in links" :key="link.name" class="py-1">
              <router-link :to="link.link" class="text-base">
                {{ link.name }}
              </router-link>
            </div>
          </div>
          <div>
            <cgn-button v-if="menu?.button_text" color-brand :url="menu.button_link" class="text-lg">
              {{ menu.button_text }}
            </cgn-button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import { directive as vClickAway } from 'vue3-click-away'
import { CognitoMenu } from '~cognito/models/Cognito/Menu'

const props = defineProps({
  menu: {
    type: CognitoMenu,
  },
})
const isHidden = ref(true)

const column1 = computed(() => {
  return props.menu?.children.find(link => link.identifier == 'mm-col-1')
})

const column2 = computed(() => {
  return props.menu?.children.find(link => link.identifier == 'mm-col-2')
})

const links = computed(() => {
  return props.menu?.children.filter(link => !link.identifier)
})

const toggle = () => {
  isHidden.value = !isHidden.value
}
const close = () => {
  isHidden.value = true
}
const router = useRouter()
router.beforeEach(() => {
  close()
})
</script>
