<template>
  <div v-show="!navParentIsHidden" class="fixed inset-0 flex z-40 lg:hidden font-display">
    <transition
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-class="opacity-100"
      leave-to-class="opacity-0"
      class="transition-opacity ease-linear duration-300"
      @after-leave="navParentIsHidden = true"
    >
      <div v-if="!navIsHidden" class="fixed inset-0 bg-black bg-opacity-25" aria-hidden="true" @click="closeNav()" />
    </transition>

    <transition
      enter-from-class="-translate-x-full"
      enter-to-class="translate-x-0"
      leave-from-class="translate-x-0"
      leave-to-class="-translate-x-full"
      class="transition ease-in-out duration-300 transform"
    >
      <div v-show="!navIsHidden" class="relative max-w-xs w-full bg-white dark:bg-gray-800 shadow-md flex flex-col overflow-y-scroll">
        <div class="p-4 flex justify-between items-center border-b-2 border-gray-200 dark:border-gray-700">
          <router-link to="/">
            <cgn-organisation-logo class="h-8 w-auto" />
          </router-link>
          <i-heroicons-solid:x class="text-gray-500 dark:text-gray-200 cursor-pointer" @click="closeNav()" />
        </div>

        <div class="p-4 space-y-2 text-gray-700 dark:text-gray-300">
          <div v-for="link in menus" :key="link.name" class="flow-root">
            <NavBarDropDownMobile
              v-if="link.children.length > 0"
              :heading="link.name"
            >
              <span v-for="childLink in link.children" :key="childLink.name">
                <NavBarDropDownMobile
                  v-if="childLink.children.length > 0"
                  :heading="childLink.name"
                >
                  <span v-for="subChildLink in childLink.children" :key="subChildLink.name">
                    <router-link
                      :to="subChildLink.link"
                      class="py-2 block font-medium text-gray-600 dark:text-gray-400"
                    >
                      {{ subChildLink.name }}
                    </router-link>
                  </span>
                </NavBarDropDownMobile>
                <router-link
                  v-else
                  :to="childLink.link"
                  class="py-2 block font-medium text-gray-600 dark:text-gray-400"
                >
                  {{ childLink.name }}
                </router-link>
              </span>
            </NavBarDropDownMobile>
            <router-link
              v-else
              :to="link.link"
              class="py-2 block font-medium hover:text-gray-900 dark:hover:text-gray-200"
            >
              {{ link.name }}
            </router-link>
          </div>
        </div>
      </div>
    </transition>
  </div>

  <header class="relative z-30 font-display">
    <nav class="bg-white dark:bg-gray-800 drop-shadow-md" aria-label="Top">
      <div class="max-w-7xl mx-auto px-4">
        <div class="h-16 flex items-center justify-between lg:justify-start">
          <!-- Logo (lg+) -->
          <div class="hidden lg:flex">
            <router-link to="/">
              <cgn-organisation-logo class="h-8 w-auto" />
            </router-link>
          </div>

          <div class="hidden lg:flex justify-center space-x-8 ml-8">
            <div v-for="link in menus" :key="link.name" class="flex items-center">
              <NavBarMegaMenu
                v-if="link.identifier == 'megamenu'"
                class="text-sm font-medium text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-100"
                :menu="link"
              />
              <NavBarDropDown
                v-else-if="link.identifier == 'dropdown'"
                class="text-sm font-medium text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-100"
                :menu="link"
              />
              <router-link
                v-else
                :to="link.link"
                class="text-sm font-medium py-2 text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-100"
              >
                {{ link.name }}
              </router-link>
            </div>
          </div>

          <div class="flex lg:hidden">
            <button
              type="button"
              class="p-2 block rounded-md text-gray-400"
              @click="openNav()"
            >
              <span class="sr-only">Open menu</span>
              <i-heroicons-solid:menu />
            </button>
          </div>

          <!-- Logo (lg-) -->
          <div class="lg:hidden">
            <router-link to="/">
              <cgn-organisation-logo class="h-8 w-auto" />
            </router-link>
          </div>

          <div class="flex lg:flex-1 items-center justify-end gap-2 ml-8">
            <cgn-dark-mode-switch
              class="text-gray-500 hover:text-gray-600 dark:text-gray-400 dark:hover:text-gray-200"
            />
          </div>

          <span class="mx-4 h-6 w-px bg-gray-200 lg:mx-6" aria-hidden="true" />

          <div class="flow-root">
            <div class="flex flex-1 items-center justify-end space-x-6 text-gray-500">
              <NavBarProfileButton />
            </div>
          </div>
        </div>
      </div>
    </nav>
  </header>
</template>

<script setup lang="ts">
import { CognitoMenu } from '~cognito/models/Cognito/Menu'

const navIsHidden = ref(true)
const navParentIsHidden = ref(true)
const openNav = () => {
  navParentIsHidden.value = false
  navIsHidden.value = false
}
const closeNav = () => {
  navIsHidden.value = true
}
const router = useRouter()
router.beforeEach(() => {
  closeNav()
})

const menus = ref<CognitoMenu[]>([])

const loadMenu = async () => {
  menus.value = await new CognitoMenu().getMenu('Main Menu', '16x3', '1920')
}

const user = useUserStore().user
watch (() => user, () => {
  // Reload menu if user login changes
  loadMenu()
}, {
  deep: true,
})
onMounted(() => {
  loadMenu()
})

onServerPrefetch(async () => {
  await loadMenu()
})
</script>
