<template>
  <footer aria-labelledby="footer-heading" class="bg-gray-50 dark:bg-gray-900 dark:text-white font-display">
    <h2 id="footer-heading" class="sr-only">
      Footer
    </h2>
    <div class="px-4 md:px-6 xl:px-20 max-w-xl mx-auto">
      <cgn-newsletter-sign-up v-slot="newsletter">
        <h1 class="text-lg xl:text-xl pt-5 mb-3 font-semibold leading-4 xl:leading-5 dark:text-white text-gray-800">
          Join our mailing list today!
        </h1>
        <form @submit.prevent="newsletter.submitted">
          <div class="flex flex-col md:flex-row w-full justify-center">
            <cgn-form-input-text v-model="newsletter.signup.first_name" placeholder="First Name" class="md:mr-2" />
            <cgn-form-input-email v-model="newsletter.signup.email" placeholder="Email" class="md:mr-2" />
            <cgn-button color-brand>
              Join
            </cgn-button>
          </div>
        </form>
        <div class="max-w-md mx-auto mt-4 text-center">
          <cgn-spinner v-if="newsletter.is_loading" />
          <cgn-alert-success v-if="newsletter.success">
            {{ newsletter.message }}
          </cgn-alert-success>
          <cgn-alert-danger v-if="newsletter.fail">
            {{ newsletter.message }}
          </cgn-alert-danger>
        </div>
      </cgn-newsletter-sign-up>
    </div>
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="py-20">
        <div class="grid grid-cols-1 lg:grid-cols-4 gap-8 text-center lg:text-left">
          <div class="mx-auto lg:mx-0">
            <router-link to="/">
              <img src="/src/assets/footer-logo.webp" width="280">
            </router-link>
          </div>
          <div v-if="column1?.name">
            <router-link :to="column1.link" class="text-lg">
              {{ column1.name }}
            </router-link>
            <div v-for="link in column1.children" :key="link.name" class="py-1">
              <router-link :to="link.link">
                {{ link.name }}
              </router-link>
            </div>
          </div>
          <div>
            <div v-for="link in links" :key="link.name" class="py-1">
              <router-link :to="link.link" class="text-lg">
                {{ link.name }}
              </router-link>
            </div>
          </div>
          <div v-if="contact_col?.name">
            <router-link :to="contact_col.link" class="text-lg">
              {{ contact_col.name }}
            </router-link>
            <div v-for="link in contact_col.children" :key="link.name" class="py-1">
              <router-link :to="link.link">
                {{ link.name }}
              </router-link>
            </div>
          </div>
        </div>
      </div>

      <div class="border-t border-gray-100 dark:border-gray-800 py-10 text-center">
        <p class="text-sm text-gray-500">
          &copy; {{ year }}. All rights reserved.
        </p>
        <p class="text-sm text-gray-500">
          Hand crafted with <i-ri:heart-fill class="inline" /> by <a href="https://jmarshall.com.au" target="_blank">Josh</a> &amp; <a href="https://zandertektalks.com.au" target="_blank">Zander</a>
        </p>
      </div>
    </div>
    <div style="position: fixed; right: 0px; top: 50%; padding: 10px; background-color: rgba(0, 0, 0, 0.3);">
      <div style="margin-bottom: 10px;">
        <a href="https://www.facebook.com/IpswichShow" target="_blank" source="iconoir:facebook-tag">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"><path d="M21 8v8a5 5 0 0 1-5 5H8a5 5 0 0 1-5-5V8a5 5 0 0 1 5-5h8a5 5 0 0 1 5 5Z" /><path d="M11 21v-9c0-2.188.5-4 4-4m-6 5h6" /></g></svg>
        </a>
      </div>
      <div style="margin-bottom: 10px;">
        <a href="https://www.instagram.com/ipswich_show_society/" target="_blank" source="iconoir:instagram">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-width="1.5"><path stroke-linecap="round" stroke-linejoin="round" d="M12 16a4 4 0 1 0 0-8a4 4 0 0 0 0 8Z" /><path d="M3 16V8a5 5 0 0 1 5-5h8a5 5 0 0 1 5 5v8a5 5 0 0 1-5 5H8a5 5 0 0 1-5-5Z" /><path stroke-linecap="round" stroke-linejoin="round" d="m17.5 6.51l.01-.011" /></g></svg>
        </a>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
import { CognitoMenu } from '~cognito/models/Cognito/Menu'
const year = ref(new Date().getFullYear())

const menus = ref<CognitoMenu[]>([])

const loadMenu = async () => {
  menus.value = await new CognitoMenu().getMenu('Footer Menu', '16x3', '1920')
}

const column1 = computed(() => {
  return menus.value.find(link => link.identifier == 'footer-col-1')
})

const contact_col = computed(() => {
  return menus.value.find(link => link.identifier == 'footer-col-contact')
})

const links = computed(() => {
  return menus.value.filter(link => !link.identifier)
})

onMounted(() => {
  loadMenu()
})

onServerPrefetch(async () => {
  await loadMenu()
})
</script>
